import domReady from '@roots/sage/client/dom-ready';
import { CountUp } from 'countup.js';
import AOS from 'aos';
import { up, toggle } from "slide-element";
import 'focus-visible/dist/focus-visible.min.js';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';

// Font awesome
library.add(faEarthAmericas);
dom.watch();

/**
 * Application entrypoint
 */
domReady(async () => {
  
  /**
   * Variables
   */
  const currentUrl = window.location.href;
  const desktopWidth = 1024;
  const mainMenuItems = document.querySelectorAll('.menu-wrapper > li > a');
  const extraMenuItems = document.querySelectorAll('.nav-extra button');
  const header = document.querySelector('#header');
  const accordionButtons = document.querySelectorAll('.accordion-btn');
  const modalButtons = document.querySelectorAll('[data-modal-id]');
  const modalClose = document.querySelectorAll('.modal-close');
  const toolTips = document.querySelectorAll('.tooltip-button');
  let initialLoad = true;
  const slideArgs = {
    duration: 200,
    easing: "ease-in-out",
  };

  /**
   * Event listeners
   */ 

  // ESC keydown event
  document.body.addEventListener('keydown', function(e) {
    if (e.key == "Escape") {
      closeDrawers();
    }
  });
  
  // Page load
  pageScroll();
  setMaxHeight('header nav .nav-primary__ul > .menu-wrapper > li.menu-item > .sub-menu__wrapper a.depth-1');
  
  // Window resize
  window.addEventListener('resize', (event) => {
    setMaxHeight('header nav .nav-primary__ul > .menu-wrapper > li.menu-item > .sub-menu__wrapper a.depth-1');
  });
  
  // Page scroll
  addEventListener('scroll', (event) => {
    pageScroll();
  });
  
  // AOS
  AOS.init({
    once: true,
    duration: 750,
    easing: 'ease-out-cubic',
    disable: 'mobile', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded',
  }); 
  
  /**
   * Modal / drawer
   */
  if (modalButtons) {
    modalButtons.forEach((button, i) => {
      button.addEventListener('click', (event) => {
        
        let modalId = button.getAttribute('data-modal-id');
        if (!modalId) return;
        
        event.preventDefault();
        
        // Activate modal & lock body
        document.getElementById(modalId).classList.add('active');
        document.body.classList.add('lock');
        
        // Check for search modal
        if (modalId === 'modal-search') {
          setTimeout(() => {
            document.querySelector('header #modal-search input[type="search"]').focus();
          }, 200);
        }
      });
    });
  }
  
  if (modalClose) {
    modalClose.forEach((el, i) => {
      el.addEventListener('click', (event) => {
        
        // Disable modal and unlock body
        closeDrawers();
      });
    });
  }
  
  
  /**
   * Accordion click events
   */
  accordionButtons.forEach((accordionBtn, i) => {
    accordionBtn.addEventListener('click', (event) => {
      accordionHandler(accordionBtn);
    });
  });
  
  /**
   * CountUp.js
   */
  if (document.querySelectorAll('.countup-number')) {
    document.querySelectorAll('.countup-number').forEach((el, i) => {
      let countUpValue = el.dataset.countupValue;
      let countUpSuffix = el.dataset.countupSuffix;
      
      let countUp = new CountUp(
        el,
        countUpValue,
        {
          enableScrollSpy: true,
          scrollSpyOnce: true,
          suffix: countUpSuffix
        }
        );
      countUp.start();
    });
  }
    
  /**
   * Smooth Scroll
   */
  // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  //   anchor.addEventListener('click', function (e) {
  //     e.preventDefault();
      
  //     if(this.getAttribute('href') !== '#') { // Don't kick in if its just a empty # link
  //       setTimeout(function() {
  //         document.querySelector(this.getAttribute('href')).scrollIntoView({
  //           behavior: 'smooth'
  //         });
  //       }, 2000);
  //     }
      
  //   });
  // });
  
  /**
   * Header functionality
   */
  // Header enter
  if (header) {
    header.addEventListener('mouseenter', (event) => {
      if (window.innerWidth < desktopWidth) return;
      
      // Disable active header
      header.classList.add('active');
    });
  } 
   
  // Header exit 
  if (header) {
    header.addEventListener('mouseleave', (event) => {
      if (window.innerWidth < desktopWidth) return;
      
      // Disable active header
      header.classList.remove('active');
      document.body.classList.remove('lock');
      
      // Disable active menu items
      mainMenuItems.forEach((mainMenuItem, i) => {
        mainMenuItem.parentElement.classList.remove('active');
      });
    });
  }
  
  // Extra navigation buttons
  if (extraMenuItems) {
    let events = ['mouseover', 'focus'];
    
    extraMenuItems.forEach((button, i) => {
      
      // For each main menu item with a sub-menu
      events.forEach((eventType, i) => {
        button.addEventListener(eventType, (event) => {
          if (window.innerWidth < desktopWidth) return;
          
          // Add header active class
          header.classList.add('active');
          
          // Open menu item's mega menu and close all other menus
          mainMenuItems.forEach((mainMenuItem, i) => {
            mainMenuItem.parentElement.classList.remove('active');
          });
        });  
      });
    });
  }
   
  // Hamburger toggle
  if (document.querySelectorAll('header .hamburger-wrapper button')) {
    document.querySelectorAll('header .hamburger-wrapper button').forEach((button, i) => {
      button.addEventListener('click', (event) => {
        
        if(header.classList.contains('active')) {
          header.classList.remove('active');
          document.body.classList.remove('lock');
        } 

        else {
          header.classList.add('active');
          document.body.classList.add('lock');
        }
      });
    });
  }
   
  // Mobile menu dropdowns
  if (mainMenuItems) {
    mainMenuItems.forEach((mainMenuItem, i) => {
      
      // For each main menu item with a sub-menu
      mainMenuItem.addEventListener('click', (event) => {
        if (window.innerWidth >= desktopWidth) return;
        if (!event.target.parentElement.classList.contains('menu-item-has-children')) return;
        
        event.preventDefault();
      
        // Toggle clicked menu item and close all others
        mainMenuItems.forEach((mainMenuItem, i) => {
          
          if (mainMenuItem === event.target) {
            mainMenuItem.parentElement.classList.toggle('active');
            toggle(mainMenuItem.nextElementSibling, slideArgs);
          } 
          
          else {
            mainMenuItem.parentElement.classList.remove('active');
            up(mainMenuItem.nextElementSibling, slideArgs);
          }
        });  
      });
    });
  }
  
  // Desktop menu mega menu
  if (mainMenuItems) {
    let events = ['mouseover', 'focus', 'click'];
    
    mainMenuItems.forEach((mainMenuItem, i) => {
      
      // For each main menu item
      events.forEach((eventType, i) => {
        mainMenuItem.addEventListener(eventType, (event) => {
          if (window.innerWidth < desktopWidth) return;
          
          // PreventDefault and lock body scroll only for items with a submenu
          if (mainMenuItem.parentElement.classList.contains('menu-item-has-children')) {
            event.preventDefault();
            document.body.classList.add('lock');
          }
          
          // Add header active class
          header.classList.add('active');
          
          // Open menu item's mega menu and close all other menus
          mainMenuItems.forEach((mainMenuItem, i) => {
          
            if (mainMenuItem === event.target) {
              mainMenuItem.parentElement.classList.add('active');
            } 
            
            else {
              mainMenuItem.parentElement.classList.remove('active');
            }
          });
        });  
      });
    });
  }
  
  // Scroll to video and highlight play button
  var hash = window.location.hash.substring(1);
  
  if(hash) {
    var videoElementContainer = document.getElementById(hash);
    
    if(videoElementContainer) {
      setTimeout(() => {
        videoElementContainer.querySelector('.play-btn').classList.toggle('pulsate');
      }, 400);
    }
  }
  
  /**
   * Functions
   */
   
  /**
   * Equal height items
   */
  function setMaxHeight(selector) {
    if (window.innerWidth < desktopWidth) return;
    
    var elements = document.querySelectorAll(selector);
    var maxHeight = 0;
  
    // Reset all elements to auto height to correctly measure their natural heights
    elements.forEach((element) => {
      element.style.height = 'auto';
    });
  
    // Find the maximum height
    elements.forEach((element) => {
      let itemHeight = element.offsetHeight;
      if (itemHeight > maxHeight) {
        maxHeight = itemHeight;
      }
    });
  
    // Set all elements to the maximum height
    elements.forEach((element) => {
      element.style.height = `${maxHeight}px`;
    });
  }
   
  /**
   * Header on scroll
   */
  function pageScroll() {
    if (pageIsScrolled()) {
      header.classList.add('scrolled');
      return;
    }
    
    if (header.classList.contains('scrolled')) {
      header.classList.remove('scrolled');
      return; 
    }
    
    return;
  }
  
  /**
   * Page scroll detection
   */
  function pageIsScrolled() {
    let scrollDistance = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    
    if (scrollDistance > 10) {
      return true;
    }
    
    return false;
  }
  
  /**
   * Reset modal drawers
   */
  function closeDrawers() {
    if (document.querySelectorAll('.modal.modal-container')) {
      document.querySelectorAll('.modal.modal-container').forEach((modal, i) => {
        modal.classList.remove('active');
      });
      
      document.body.classList.remove('lock');
    }
  }
  
  /**
   * Accordion functionality
   */
  function accordionHandler(accordionTrigger) {
    if (!accordionTrigger) return;
    
    // Get the acoordion content container by id given in accordion button attribute called "data-container"
    var container = document.getElementById(accordionTrigger.dataset.container);
    
    // Toggle class on button that will flip the arrow
    accordionTrigger.classList.toggle('open'); // For main accordions
    accordionTrigger.classList.toggle('active'); // Mobile menu accordions
    
    // Aria stuff
    if(accordionTrigger.classList.contains('open') || accordionTrigger.classList.contains('active')) { 
      accordionTrigger.setAttribute('aria-expanded', 'true'); 
    } 
    else {
      accordionTrigger.setAttribute('aria-expanded', 'false');
    }

    // Accordion dropdown container toggle
    if (!container.classList.contains('active')) {
      toggle(container, slideArgs);
      container.classList.add('active');
    }
    
    else { 
      toggle(container, slideArgs);
      container.classList.remove('active');
    }
  }
  
  // Read More Expand Text
  var els = document.querySelectorAll(".read-more-btn");
  
  window.addEventListener('resize', (event) => {
    
    for (var i = 0; i < els.length; i++) {
      var cc = els[i].parentNode.parentNode.children[0];
      var ccfirst = cc.children[0];
      cc.style.maxHeight = (ccfirst.offsetHeight + 24) + "px";
    }
    
  });
  
  setTimeout(function() {

  for (var i = 0; i < els.length; i++) {
    
    // Sets the max-height pre-click to the height of the first paragraph
    var cc = els[i].parentNode.parentNode.children[0];
    var ccfirst = cc.children[0];
    cc.style.maxHeight = (ccfirst.offsetHeight + 24) + "px";
    
    
      els[i].addEventListener("click", function(event) {
          event.preventDefault();
          var totalHeight = 0;
          var btn = event.target;
          var btncontainer = btn.parentNode;
          var contentcontainer = btncontainer.parentNode.children[0];
          var content = contentcontainer.children;
          var contentfirst = contentcontainer.children[0];

          for (var j = 0; j < content.length; j++) {
              totalHeight += content[j].offsetHeight;
          }

          contentcontainer.style.height = contentcontainer.offsetHeight + "px";
          contentcontainer.style.maxHeight = "9999px";

          setTimeout(function() {
              contentcontainer.style.height = totalHeight + "px";
          }, 0);
          
          contentcontainer.classList.add('expanded');
          
          setTimeout(function() {
              contentcontainer.style.height = 'auto';
          }, 401);

          btncontainer.style.display = "none";

      });
    }
    
  }, 100);
  
  function watchForClassChange(targetElement, className, videoElement) {
    // Select the target element
    const target = targetElement;
      
      // Create a MutationObserver instance
      const observer = new MutationObserver((mutationsList, observer) => {
          for (let mutation of mutationsList) {
              if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                  // Check if the target element now has the specified class
                  if (target.classList.contains(className)) {
                      // Play the video within the target element
                      videoElement.play();
                  }
              }
          }
      });
      
      // Start observing the target element for changes in its attributes
      observer.observe(target, { attributes: true });
  }
  
  // Video play on scroll
  document.querySelectorAll('.video-on-scoll-container').forEach((container, i) => {
    const videoElement = container.querySelector('video'); // Change this to match your video element
  
    watchForClassChange(container, 'aos-animate', videoElement);
  });
  
  // Removes some styling when using facets on post archive page
  document.addEventListener('facetwp-refresh', function() {
    
    if (!initialLoad) { // Prevents auto scroll on page load
    
      document.querySelectorAll('.facetwp-template').forEach((container, i) => {
        if('pager' in FWP.settings) {
          
          if ('' != FWP.buildQueryString() || FWP.settings.pager.page != 1) {
            container.classList.add('filtered');
          }
          else {
            container.classList.remove('filtered');
          }
          
          container.scrollTo({
            behavior: 'smooth',
          });
        }
      });
      
      // Scroll to top of post grid on facet change
      document.querySelector('.facetwp-facet').scrollIntoView({
        behavior: 'smooth',
      });
    
    }
    
    initialLoad = false; // Prevents auto scroll on page load
    
  });
  
  // Video Play/Pause
  window.playVideo = (thisv, e) => {
    e.preventDefault();
    
    var video = thisv.nextElementSibling;
    var playButton = thisv;
    
    if (video.paused == true) {
      // Play the video
      video.play();
      video.setAttribute('controls', 'true')
      playButton.classList.add('hidden');
  
      // Update the button text to 'Pause'
      playButton.innerHTML = "Pause";
    } else {
      // Pause the video
      video.pause();
       playButton.classList.remove('hidden');
  
      // Update the button text to 'Play'
      playButton.innerHTML = "Play";
    }
  }
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
